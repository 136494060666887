import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $containers = $('.single-line');
    if (!$containers.length) {
        return;
    }

    addAction(LAYOUT, () => {
        $containers.each((index, el) => {
            const $container = $(el);
            $container.css('font-size', '');
            let maxFontSize = Math.ceil(parseFloat($container.css('font-size')) / 10) * 20 || 100;
            let minFontSize = 0;
            let sized = false;
            let count = 0;
            $container.addClass('measure');
            const targetWidth = $container.width();
            $container.removeClass('measure');

            if ($container.width() > targetWidth) {
                do {
                    count += 1;
                    const avg = (maxFontSize + minFontSize) / 2;
                    $container.css('font-size', `${avg}px`);
                    const newWidth = $container.width();
                    const threshold = ((targetWidth - newWidth) / targetWidth) * 100;
                    if (threshold >= 0) {
                        minFontSize = avg;
                    } else {
                        maxFontSize = avg;
                    }

                    sized = count > 20 || (threshold < 5 && threshold >= 0);
                } while (!sized);
            }

            $container.addClass('show');
        });
    });
});
